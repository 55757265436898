import { Location } from 'vue-router';
import { Draft } from '@/shared/store/onboarding';

type Dictionary<T> = { [key: string]: T };

const requiredUGCFieldsByStep: { [key: string]: any; } = {
  'new-campaign': [
    'artistName',
    'releaseName',
    'releaseGenre',
    'releaseType',
  ],
  intermission: [],
  'recommended-ads': [],
  budget: [],
  checkout: [],
};

function getAppropriateStep(draft: Draft, currStep: string, params?: Dictionary<string>): Location {
  let appropriateStep: Location = {
    name: currStep,
    params,
  };
  const steps = Object.keys(requiredUGCFieldsByStep);
  const stepIndex = steps.indexOf(currStep);
  if (stepIndex > -1) {
    let i = 0;
    steps.forEach((stepIterator) => {
      if (i <= stepIndex) {
        requiredUGCFieldsByStep[stepIterator].forEach((fieldName: string) => {
          if (!draft!.ugc!.fields[fieldName]) {
            appropriateStep = {
              name: stepIterator,
              params,
            };
          }
        });
        i += 1;
      }
    });
  }
  return appropriateStep;
}

function isValid(draft: Draft, step: string, params?: Dictionary<string>): boolean {
  return (getAppropriateStep(draft, step, params).name === step);
}

export default {
  isValid,
  getAppropriateStep,
};
