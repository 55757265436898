














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  mixins: [],
  components: {},
  props: {},
  validations: {},
})
export default class AddAudioButton extends Vue {
  @Prop({ default: 'uploadAudioButton' })
  buttonClass: string;

  @Prop({ default: ['far', 'music'] })
  buttonIcon: string[];

  @Prop({ default: 'Add a Song' })
  buttonText: string;
}
