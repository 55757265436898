





















import { Component, Vue, Prop } from 'vue-property-decorator';
import AddAudioButton from '@/shared/components/common/AddAudioButton.vue';

@Component({
  components: {
    AddAudioButton,
  },
})
export default class UploaderAudio extends Vue {
  $refs!: {
    songFileUploader: any,
  };

  @Prop({ default: 'uploadAudioButton' })
  buttonClass: string;

  @Prop({ default: () => ['far', 'music'] })
  buttonIcon: string[];

  @Prop({ default: 'Add a Song' })
  buttonText: string;

  fileToUpload: any = null;

  passClickToFileInput(evt: Event) {
    evt.preventDefault();
    document.getElementById('songFileUploader')!.click();
  }

  emitUploadAudioEvent(file: File) {
    this.$emit('upload-audio', file);
  }
}
